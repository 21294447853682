import React, { useRef } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { StyledTextField, StyledButton } from '../../../components/StyledComponents';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';

const MessageInput = ({
    sendMessage,
    isRecording,
    startRecording,
    stopRecording,
    isMobile,
    handleFileChange
}) => {
    const messageRef = useRef('');

    const handleSendMessage = () => {
        sendMessage(messageRef.current.value);
        messageRef.current.value = ''; // Clear the input field
    };


    return (
        <>
                <StyledTextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    inputRef={messageRef}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                    sx={{ flex: 1, marginRight: 1 }}
                    autoComplete="off"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={isRecording ? stopRecording : startRecording} color="primary">
                                    {isRecording ? <StopIcon sx={{ color: 'red' }} /> : <MicIcon sx={{ color: isMobile ? 'white' : 'black'  }} />}
                                </IconButton>
                                <IconButton component="label">
                                    <AttachFileIcon sx={{ color: isMobile ? 'white' : 'black' }}/>
                                    <input type="file" hidden multiple onChange={handleFileChange} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <StyledButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                        handleSendMessage();
                    }}
                >
                        Send
                </StyledButton>
        </>
    );
};

export default MessageInput;