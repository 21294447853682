import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { StyledButton } from '../../components/StyledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ServerList = ({ Servers, handleServerSelectMobile, DMS, handleDMSelect }) => {
    const navigate = useNavigate();

    return (
        <Grid container sx={{ height: '100vh' }}>
            <Grid item xs={12} sx={{ padding: 2 }}>
            <Typography marginTop="64px" variant="h4">DMS:</Typography>
                {DMS.map((dm, index) => (
                    <Box key={index} sx={{ marginTop: 2 }}>
                        <button onClick={() => handleDMSelect(dm)} style={{ color: '#ffffff', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}>
                            <Typography variant="h5">
                                {dm.name.username}
                            </Typography>
                        </button>
                    </Box>
                ))}
                <Typography marginTop="64px" variant="h4">Servers:</Typography>
                {Servers.map((server, index) => (
                    <Box key={index} sx={{ marginTop: 2 }}>
                        <button onClick={() => handleServerSelectMobile(server)} style={{ color: '#ffffff', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}>
                            <Typography variant="h5">
                                {server.name}
                            </Typography>
                        </button>
                    </Box>
                ))}
            </Grid>
            <Box sx={{ position: 'fixed', bottom: 16, left: 16 }}>
                <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/home/create-server')}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </StyledButton>
            </Box>
            <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/home/join-server')}
                >
                    <FontAwesomeIcon icon={faSignInAlt} />
                </StyledButton>
            </Box>
        </Grid>
    );
};

export default ServerList;