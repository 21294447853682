import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, useMediaQuery, Grid } from '@mui/material';
import { StyledButton } from '../components/StyledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ServerList from './MobileSubPages/ServerList';
import CreateServerPage from './MobileSubPages/CreateServer';
import JoinServerPage from './MobileSubPages/JoinServer';
import ServerDetails from './MobileSubPages/ServerDetails';

const MainPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkTokenCookie = () => {
            const cookies = document.cookie.split(';');
            const tokenCookie = cookies.find(cookie => cookie.trim().startsWith('chattoken='));
            if (!tokenCookie) {
                navigate(`/login?redirect=${location.pathname}`);
            }
        };

        checkTokenCookie();
    }, [navigate, location.pathname]);

    const isMobile = useMediaQuery('(max-width: 750px)');
    const [Servers, setServers] = useState(null);
    const [SelectedServer, setSelectedServer] = useState(null);
    const [joinServerId, setJoinServerId] = useState('');
    const [createServerName, setCreateServerName] = useState('');
    const [alertPropmted, setAlertPrompted] = useState(false);
    const [DMS, setDMS] = useState(null);
    
    const handleServerSelectMobile = useCallback((server) => {
        setSelectedServer(server);
        navigate(`/home/server/${server._id}`);
    }, [navigate]);

    const handleDMSelect = useCallback((dm) => {
        setSelectedServer(dm);
        navigate(`/home/dm/${dm._id}`);
    }, [navigate]);

    useEffect (() => {
        axios.get('https://api.estopia.net/api/servers', {
            withCredentials: true
        })
            .then(response => {
                let Servers = response.data.servers;
                for (let i = 0; i < Servers.length; i++) {
                    if(Servers[i].name.length > 64) {
                        Servers[i].name = Servers[i].name.slice(0, 65) + "...";
                    }
                }
                setServers(Servers);
                if(location.pathname === '/home' && !isMobile){
                    handleServerSelectMobile(Servers[0]);
                }
            })
            .catch(error => {
                console.error(error);
            });

        axios.get('https://api.estopia.net/api/user', {
            withCredentials: true
        })
            .then(responsee => {
                axios.get('https://api.estopia.net/api/dms', {
                    withCredentials: true
                })
                    .then(response => {
                        let Dm = response.data.directs.map(dm => {
                            if (dm.requesterId._id === responsee.data._id) {
                                dm.name = dm.recipientId;
                            } else {
                                dm.name = dm.requesterId;
                            }
        
                            return dm;
                        });
        
                        console.log(Dm);
                        setDMS(Dm);
                    })
                    .catch(error => {
                        console.error(error);
                    }
                )
            })
            .catch(error => {
                console.error(error);
            }
        )


    }, [location.pathname, handleServerSelectMobile, isMobile]);

    const handleJoinServer = async (selectedServerId) => {
        try {
            setAlertPrompted(true);
            const response = await axios.get(`https://api.estopia.net/api/servers/state/${selectedServerId}`, {
                withCredentials: true
            });
    
            if (response.data.joinable) {
                if (response.data.reqnotmet) {
                    alert('You do not meet the requirements to join this server');
                    navigate('/home/verify');
                    return;
                }
                
                alert('Server is joinable, Redirecting to Join Server Page');
                setJoinServerId(selectedServerId);
                navigate('/home/join-server');
                setAlertPrompted(false);
                return;
            } else {
                if(response.data.banned) {
                    alert('You are banned from this server');
                    setAlertPrompted(false);
                    navigate('/home');
                    return;
                } else {
                    alert('Server is private or non-existent');
                    setAlertPrompted(false);
                    navigate('/home');
                    return;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    if(alertPropmted) {
        return (
            <Typography>Loading...</Typography>
        );
    }

    const serverIdMatch = location.pathname.match(/^\/home\/server\/([^/]+)/);
    if(!SelectedServer && serverIdMatch) {
        if (Servers) {
            const selectedServerId = serverIdMatch ? serverIdMatch[1] : null;
            const selectedServer = Servers.find(server => server._id === selectedServerId);
            if (selectedServer) {
                setSelectedServer(selectedServer);
            } else {
                handleJoinServer(selectedServerId);
            }
        } else {
            return (
                <Typography>Loading...</Typography>
            );
        }
    }

    const serverDMmatch = location.pathname.match(/^\/home\/dm\/([^/]+)/);
    if(!SelectedServer && serverDMmatch) {
        if (DMS) {
            const selectedServerId = serverDMmatch ? serverDMmatch[1] : null;
            const selectedServer = DMS.find(server => server._id === selectedServerId);
            if (selectedServer) {
                setSelectedServer(selectedServer);
            } else {
                alert('DM not found');
                navigate('/home');
            }
        } else {
            return (
                <Typography>Loading...</Typography>
            );
        }
    }

    if(!Servers || !DMS) {
        return (
            <Typography>Loading...</Typography>
        );
    }

    if(isMobile) {
        return (
            <Routes>
                <Route path="/" element={<ServerList Servers={Servers} handleServerSelectMobile={handleServerSelectMobile} handleDMSelect={handleDMSelect} DMS={DMS}/>} />
                <Route path="/create-server" element={<CreateServerPage createServerName={createServerName} setCreateServerName={setCreateServerName} />} />
                <Route path="/join-server" element={<JoinServerPage joinServerId={joinServerId} setJoinServerId={setJoinServerId} />} />
                <Route path="/server/:id/*" element={<ServerDetails SelectedServer={SelectedServer} />} />
                <Route path="/dm/:id/*" element={<ServerDetails SelectedServer={SelectedServer} />} />
            </Routes>
        );
    }

    return (
        <Grid container sx={{ height: 'calc(100vh-64px)' }}>
            <Grid item sx={{ width: '250px', backgroundColor: '#121212', padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" marginTop="64px">DMS:</Typography>
                {DMS.map((dm, index) => (
                    <Box key={index} sx={{ marginTop: 2 }}>
                        <Typography variant="h6">
                            <button onClick={() => handleDMSelect(dm)} style={{ color: '#ffffff', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}>
                                <Typography variant="h6">
                                    {dm.name.username}
                                </Typography>
                            </button>
                        </Typography>
                    </Box>
                ))}
                <Typography variant="h5" marginTop="64px">Servers:</Typography>
                {Servers.map((server, index) => (
                    <Box key={index} sx={{ marginTop: 2 }}>
                        <Typography variant="h6">
                            <button onClick={() => handleServerSelectMobile(server)} style={{ color: '#ffffff', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}>
                                <Typography variant="h6">
                                    {server.name}
                                </Typography>
                            </button>
                        </Typography>
                    </Box>
                ))}
                <Box sx={{ marginTop: 4 }}>
                    <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/home/create-server')}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </StyledButton>
                </Box>
                <Box>
                    <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/home/join-server')}
                    >
                        <FontAwesomeIcon icon={faSignInAlt} />
                    </StyledButton>
                </Box>
            </Grid>
            <Grid item sx={{ flexGrow: 1, backgroundColor: '#1e1e1e', marginTop: "64px", width: 'calc(100% - 250px)', display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Routes>
                    <Route path="/" element={<Typography height="100vh">Select a server or create a new one.</Typography>} />
                    <Route path="/create-server" element={<CreateServerPage createServerName={createServerName} setCreateServerName={setCreateServerName} />} />
                    <Route path="/join-server" element={<JoinServerPage joinServerId={joinServerId} setJoinServerId={setJoinServerId} />} />
                    <Route path="/server/:id/*" element={<ServerDetails SelectedServer={SelectedServer} />} />
                    <Route path="/dm/:id/*" element={<ServerDetails SelectedServer={SelectedServer} />} />
                </Routes>
            </Grid>
        </Grid>
    )
};

export default MainPage;