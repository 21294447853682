// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Support from './pages/Support';
import TOS from './pages/TOS';
import Privacy from './pages/PrivacyPolicy';
import MainPage from './pages/MainPage';
import Discovery from './pages/Discovery';
import Settings from './pages/Settings';
import Milo from './pages/Milo';
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import './App.css';

function App() {
  return (
    <Router>
      <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9917296124085330" crossorigin="anonymous"></script>
      </Helmet>
      <Header />
      <Routes>
        <Route path="/" element={<Container> <Home /> </Container>} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/support" element={<Support />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/home" element={<MainPage />} />
        <Route path="/home/*" element={<MainPage />} />
        <Route path="/discovery" element={<Discovery />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/milo" element={<Milo />} />
      </Routes>
    </Router>
  );
}

export default App;
