import React, {useEffect} from 'react';
import { Container, Box, Typography, useMediaQuery, Card, CardContent, Divider } from '@mui/material';
import AOS from 'aos';

function TOS() {
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        AOS.init({
          duration: 500, // Animation duration in milliseconds
        });
      }, []);

    return (
        <Container maxWidth="lg" alignSelf="center" sx={{ overflowX: 'hidden', overflowY: 'hidden' }}>
        <Box textAlign="center" padding={isMobile ? "32px" : "64px"}>
          <Typography variant={isMobile ? "h4" : "h3"} data-aos="fade-down" marginTop={isMobile ? "32px" : "64px"}>
            Terms of Service
          </Typography>
          <Typography variant={isMobile ? "body1" : "h5"} data-aos="fade-up" marginTop={isMobile ? "16px" : "32px"}>
            Please read our terms of service carefully
          </Typography>
        </Box>
        <Card sx={{ marginTop: '32px', padding: '16px', backgroundColor: '#121212' }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              1. General Terms
            </Typography>
            <Typography variant="body1" paragraph>
              By accessing and using our service, you accept and agree to be bound by the terms and provision of this agreement. 
              In addition, when using these particular services, you shall be subject to any posted guidelines or rules applicable 
              to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree 
              to abide by the above, please do not use this service.
            </Typography>
            <Divider sx={{ marginY: '16px' }} />
            <Typography variant="h5" gutterBottom>
              2. User Responsibilities
            </Typography>
            <Typography variant="body1" paragraph>
              Users are responsible for maintaining the confidentiality of their account and password and for restricting access to 
              their computer. Users agree to accept responsibility for all activities that occur under their account or password.
            </Typography>
            <Divider sx={{ marginY: '16px' }} />
            <Typography variant="h5" gutterBottom>
              3. Prohibited Activities
            </Typography>
            <Typography variant="body1" paragraph>
              Users are prohibited from using the service for any unlawful purpose or any purpose prohibited by these terms. 
              Prohibited activities include, but are not limited to: (a) attempting to interfere with the service to any user, 
              host, or network; (b) using the service to send unsolicited emails, including promotions or advertisements for products 
              or services; (c) attempting to gain unauthorized access to the service, other accounts, computer systems, or networks 
              connected to the service.
            </Typography>
            <Divider sx={{ marginY: '16px' }} />
            <Typography variant="h5" gutterBottom>
              4. Termination
            </Typography>
            <Typography variant="body1" paragraph>
              We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason 
              whatsoever, including without limitation if you breach the terms. All provisions of the terms which by their nature 
              should survive termination shall survive termination, including, without limitation, ownership provisions, warranty 
              disclaimers, indemnity, and limitations of liability.
            </Typography>
            <Divider sx={{ marginY: '16px' }} />
            <Typography variant="h5" gutterBottom>
              5. Outh Providers
            </Typography>
            <Typography variant="body1" paragraph>
              When using our service, you agree to follow the terms of service of any third-party service providers that you use.
            </Typography>
            <Divider sx={{ marginY: '16px' }} />
            <Typography variant="h5" gutterBottom>
              6. Changes to Terms
            </Typography>
            <Typography variant="body1" paragraph>
              We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material 
              we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change 
              will be determined at our sole discretion.
            </Typography>
            <Divider sx={{ marginY: '16px' }} />
            <Typography variant="h5" gutterBottom>
              Credits to Design, Code, and Content
            </Typography>
            <Typography variant="body1" paragraph>
              - Jay (Design & Code)<br />
              - Leo (Head of Design)<br />
              - Tyler (Design)<br />
              - Jayden (Ideas, Project Manager, Design)
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );

}

export default TOS;