import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Container, Button, TableRow, TableCell,Table, TableContainer, Paper, TableBody } from '@mui/material';
import axios from 'axios';
import { Save, ExitToApp} from '@mui/icons-material';
import { StyledButton } from '../components/StyledComponents';

const Settings = () => {
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState('');
    // const [pronouns, setPronouns] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchUserDetails = async () => {
        try {
            const response = await axios.get('https://api.estopia.net/api/user', {
                withCredentials: true
            });
            setUser(response.data);
            setUsername(response.data.username);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get('https://api.estopia.net/api/user', {
                    withCredentials: true
                });
                setUser(response.data);
                setUsername(response.data.username);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, []);

    const handleSave = async () => {
        if(username.trim() === '') {
            alert('Username cannot be empty');
            return;
        }

        if(username.length > 64) {
            alert('Username cannot be longer than 64 characters');
            return;
        }

        if(username.length < 3) {
            alert('Username must be at least 3 characters long');
            return;
        }

        try {
            await axios.put('https://api.estopia.net/api/user', {
                username
            }, {
                withCredentials: true
            });
            alert('Username updated successfully');
            fetchUserDetails();
        } catch (err) {
            setError(err.message);
        }
    };

    const deleteAccount = async () => {
        if(window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
            try {
                await axios.delete('https://api.estopia.net/api/user', {
                    withCredentials: true
                });
                alert('Account deleted successfully');
                window.location.href = '/login';
            } catch (err) {
                setError(err.message);
            }
        }
    };

    const handleVerifyEmail = () => {
        setIsButtonDisabled(true);
        axios.post('https://api.estopia.net/api/user/verify', {}, {
            withCredentials: true
        })
            .then(response => {
                console.log('Verification email sent:', response.data);
                alert('Verification email sent. Please check your inbox.');
            })
            .catch(error => {
                console.error('Error sending verification email:', error);
                alert('Failed to send verification email. Please try again later.');
                setIsButtonDisabled(false); // Re-enable the button if there's an error
            });
    };

    if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><Typography>Loading...</Typography></Box>;
    if (error) return <Typography marginTop="64px" color="error">Error: {error}</Typography>;

    const emailVerificationSentDate = new Date(user.emailOwnershipCodeExpires);
    emailVerificationSentDate.setDate(emailVerificationSentDate.getDate() - 1);

    return (
        <Container>
            <Box padding={2} marginTop="64px">
                <Typography variant="h4" gutterBottom>Settings</Typography>
                {user ? (
                    <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', color: 'white'}}>
                        <Table>
                            <TableBody sx={{color: 'white'}}>
                                <TableRow>
                                    <TableCell sx={{ color: '#ffffff', borderBottom: 'none', paddingRight: '5px', width: '200px' }}>Username</TableCell>
                                    <TableCell colSpan={2} sx={{ borderBottom: 'none', paddingLeft: '0px' }}>
                                        <TextField
                                            variant="outlined"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            fullWidth
                                            sx={{
                                                input: { color: '#ffffff' },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#ffffff',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#ffffff',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#ffffff',
                                                    },
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: '#ffffff',
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: '#ffffff',
                                                },
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {
                                  /*
                                  This is code for pronouns, but it is not used in the current version of the app
                                    <TableRow sx={{ borderBottom: 'none' }}>
                                        <TableCell sx={{ color: '#ffffff', borderBottom: 'none' }}>Pronouns</TableCell>
                                        <TableCell colSpan={2} sx={{ borderBottom: 'none', paddingLeft: '0px' }}>
                                            <TextField
                                                variant="outlined"
                                                value={pronouns}
                                                onChange={(e) => setPronouns(e.target.value)}
                                                fullWidth
                                                sx={{
                                                    input: { color: '#ffffff' },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#ffffff',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#ffffff',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#ffffff',
                                                        },
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: '#ffffff',
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#ffffff',
                                                    },
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                  */
                                }

                                <TableRow sx={{ borderBottom: 'none' }}>
                                    <TableCell sx={{ color: '#ffffff', borderBottom: 'none' }}>User ID</TableCell>
                                    <TableCell sx={{ color: '#ffffff', borderBottom: 'none' }}>{user._id}</TableCell>
                                </TableRow>
                                <TableRow sx={{ borderBottom: 'none' }}>
                                    <TableCell sx={{ color: '#ffffff', borderBottom: 'none' }}>Email</TableCell>
                                    <TableCell sx={{ color: '#ffffff', borderBottom: 'none' }}>{user.email} ({user.emailVerified ? "Verified" : "Not Verified"})</TableCell>
                                    {!user.emailVerified && (
                                        <TableCell sx={{borderBottom: 'none'}}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleVerifyEmail}
                                                    disabled={isButtonDisabled}
                                                >
                                                    Resend Verification Email
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableRow sx={{ borderBottom: 'none' }}>
                                    <TableCell sx={{ color: '#ffffff', borderBottom: 'none' }}>Join Date</TableCell>
                                    <TableCell sx={{ color: '#ffffff', borderBottom: 'none' }}>{new Date(user.createdAt).toLocaleDateString()}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>No user details available.</Typography>
                )}
                <Box marginTop={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        <Save />
                         Save Changes
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={deleteAccount}
                    >
                        <ExitToApp />
                         Delete Account
                    </StyledButton>
                </Box>
            </Box>
        </Container>
    );
};

export default Settings;