import React, {useEffect} from 'react';
import { Container, Box, Typography, useMediaQuery, Card, CardContent, Divider } from '@mui/material';
import AOS from 'aos';

function Privacy() {
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        AOS.init({
          duration: 500, // Animation duration in milliseconds
        });
      }, []);

    return (
        <Container maxWidth="lg" alignSelf="center" sx={{ overflowX: 'hidden', overflowY: 'hidden' }}>
        <Box textAlign="center" padding={isMobile ? "32px" : "64px"}>
          <Typography variant={isMobile ? "h4" : "h3"} data-aos="fade-down" marginTop={isMobile ? "32px" : "64px"}>
            Privacy Policy
          </Typography>
          <Typography variant={isMobile ? "body1" : "h5"} data-aos="fade-up" marginTop={isMobile ? "16px" : "32px"}>
            Effective as of 15th, November 2024
          </Typography>
        </Box>
        <Card sx={{ marginTop: '32px', padding: '16px', backgroundColor: '#121212' }}>
        <CardContent>
          <Typography variant="body1" paragraph>
          Estopia (“we,” “us,” or “our”) values your privacy. This Privacy Policy explains how we collect, use, and protect your information. By using our services, you agree to this Privacy Policy.
          </Typography>
          <Typography variant="h5" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            - <bold>Account Information: </bold>Username, email and password.<br />
            - <bold>Uploaded Media: </bold>Any media you choose to upload.<br />
            - <bold>Messages: </bold>Any messages you send.<br />
            - <bold>Third-Party Signups: </bold>If you use Google OAuth or Discord OAuth to sign up, we collect your email and username from these parties.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            - <bold>Account Verification: </bold>To verify ownership of your email address.<br />
            - <bold>User Experience: </bold>To enable features like accessing servers, messaging, and sharing content.<br />
            - <bold>Session Management: </bold>To keep you logged in with session tokens.<br />
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            3. Account Requirements
          </Typography>
          <Typography variant="body1" paragraph>
            To access our services, you must create an account with an email, username, and password.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />

          <Typography variant="h5" gutterBottom>
            4. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            - Your data is retained until you request deletion.<br />
            - Upon account deletion, all messages and media associated with your account are removed.<br />
            - If you are banned from a server, messages and media you’ve shared there are automatically deleted.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            5. User Rights
          </Typography>
          <Typography variant="body1" paragraph>
            You have the right to:<br />
            - Delete your account and all associated data on the Settings Page.<br />
            - Request account, message, or media deletion by contacting us at support@estopia.net<br/>
            - View other users in servers or channels you post content in.<br />
            - Request a copy of your data by contacting us at support@estopia.net<br />
          </Typography>
          <Divider sx={{ marginY: '16px' }} />

          <Typography variant="h5" gutterBottom>
            6. Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            - Passwords are securely hashed.<br />
            - All communications can be encrypted with SSL. ( default )
          </Typography>
          <Divider sx={{ marginY: '16px' }} />

          <Typography variant="h5" gutterBottom>
            7. Cookies and Tracking
          </Typography>
          <Typography variant="body1" paragraph>
            We use session tokens to manage your login status.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />

          <Typography variant="h5" gutterBottom>
            8. Third-Party Integrations
          </Typography>
          <Typography variant="body1" paragraph>
            We use Google OAuth and Discord OAuth to simplify account creation and only collect email and username through these services.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            9. Legal Compliance
          </Typography>
          <Typography variant="body1" paragraph>
            We strive to comply with applicable data protection laws. If you have specific questions, contact us at support@estopia.net.
          </Typography>
          <Divider sx={{ marginY: '16px' }} />

          <Typography variant="h5" gutterBottom>
            10. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or privacy concerns, please contact us at:<br />
            - support@estopia.net<br />
            - jay@estopia.net
          </Typography>
          <Divider sx={{ marginY: '16px' }} />
          
          <Typography variant="h5" gutterBottom>
            11. Changes to Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify this Privacy Policy at any time. Any changes will be communicated via updates on our platform.
          </Typography>
        </CardContent>
      </Card>
    </Container>
    );

}

export default Privacy;